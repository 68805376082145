<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            label="대상년도"
            type="year"
            default="today"
            v-model="searchParam.participantYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="statusItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="completeFlag"
            label="진행상태"
            v-model="searchParam.completeFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.completeFlag)]">
                    {{ props.row.completeFlagName }}
                  </div>
                  <div :class="['text-grid-etc', 'txt-box-grid', 'text-gray-box']">
                    {{ props.row.participantYear }}
                  </div>
                  <div :class="['text-grid-etc', 'txt-box-grid', 'text-gray-box']">
                    {{ props.row.participantRound + '회차' }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.participantTitle }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.userName }}
                  </div>
                  <div class="text-grid-etc">
                    {{ '작성일자: ' + props.row.writeDate }}
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'satisfactionSurvey',
  data() {
    return {
      searchParam: {
        plantCd: null,
        userId: '',
        participantYear: '',
        completeFlag: null,
      },
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'participantYear',
            field: 'participantYear',
            label: '대상년도',
            style: 'width:100px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'participantRound',
            field: 'participantRound',
            label: '회차',
            style: 'width:80px',
            align: 'center',
            sortable: true,
          },
          {
            name: 'participantTitle',
            field: 'participantTitle',
            label: '제목',
            style: 'width:400px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'completeFlagName',
            field: 'completeFlagName',
            label: '진행상태',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: false,
        width: '80%',
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
      statusItems: [
        { code: 'N', codeName: '작성중' },
        { code: 'Y', codeName: '설문완료' },
      ],
    };
  },
  computed: {
    isHeaDept() { 
      return this.$_.indexOf(this.$store.getters.auths, 'SAGI000026') > -1 
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.etr.participant.survey.url;
      // code setting

      // if (!this.isHeaDept) {
        this.$set(this.searchParam, 'userId', this.$store.getters.user.userId);
      // }
      
      if (this.$route.query.heaEtrParticipantStatusId) {
        this.routeGetList();
      } else {
        this.getList();
      }
      // list setting
    },
    routeGetList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        let idx = this.$_.findIndex(this.grid.data, {heaEtrParticipantStatusId: this.$route.query.heaEtrParticipantStatusId, userId: this.$store.getters.user.userId})

        if (idx > -1) {
          this.linkClick({heaEtrParticipantStatusUserId: this.grid.data[idx].heaEtrParticipantStatusUserId});
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내', // 안내
            message: '설문조사 대상자가 아닙니다.',
            type: 'warning', // success / info / warning / error
          });
        }
      },);
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row) {
      this.popupOptions.title = '만족도 조사 상세';
      this.popupOptions.target = () => import(`${'./satisfactionSurveyDetail.vue'}`);
      this.popupOptions.param = {
        heaEtrParticipantStatusUserId: row.heaEtrParticipantStatusUserId,
      };
      this.popupOptions.visible = true;
      this.popupOptions.width = '99%';
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      switch(cd) {
        case 'Y': // 설문완료
          cls = 'txt-box-grid text-primary-box';
          break;
        default: // 작성중
          cls = 'txt-box-grid text-orange-box';
          break;
      }
      return cls;
    },
  }
};
</script>
